.loading-container {
	position: fixed;
	background-color: #4e3e53;
    /* rgb(151 118 149); */
	z-index: 100;
	height: 100vh;
	width: 100vw;
	top: 0;
	left: 0;
	display: flex;
	justify-content: center;
	align-items: center;
}

#animation-loading {
	overflow: hidden;
	transition: height 2s;
}

#animation-loading.animate {
	height: 0;
}

.glowing-square {
    display: flex;
    justify-content: center;
    align-items: center;
  width: 100px;
  height: 35px;
  z-index: 1;
  background-color: transparent!important;
  animation: glowing 0.5s ease-in-out infinite alternate;
}

@keyframes glowing {
  from {
    box-shadow: 0 0 100px 20px #f0f, 0 0 60px 20px #0ff, 0 0 70px 20px #fbefff, 0 0 80px 20px #e60073, 0 0 100px 20px #710097;
  }
  to {
    box-shadow: 0 0 80px 20px #d65bff, 0 0 70px 20px #ff4da6, 0 0 40px 20px #ff4da6, 0 0 90px 20px #ff4da6, 0 0 130px 20px #710097;
  }
}


.glowing-square {
    transition: opacity 0.5s fade-out; 
}

.animate .glowing-square{
    opacity: 0; 
}