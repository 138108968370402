.portfolio-container {
	margin-top: 130px;
	margin-bottom: 130px;
	width: 100%;
	min-height: 615px;
	height: fit-content;
	box-shadow: 1px 1px 10px 2px rgba(119, 89, 117, 0.82);
	transition: height 0.6s ease-in-out;
	animation: myAnim3 0.6s linear 0s 1 normal both;
}

.portfolio-headings {
	display: flex;
	justify-content: space-around;
	width: 100%;
	background-color: rgba(119, 89, 117, 0.199);
}

.portfolio-link {
	margin: 20px 0;
}

.portfolio {
	text-align: center;
	transition: background-color 0.5s ease-in-out;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	height: fit-content;
}

.inner-portfolio {
	max-width: 1000px;
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
	grid-gap: 30px 5px;
	justify-items: center;
	align-items: center;
	transition-delay: 0.2s;
	margin: 60px 0 60px 0;
	transition: 0.2s;
	animation: myAnim3 0.5s ease 0s 1 normal forwards;
}

.inner-portfolio-item-title {
	font-size: 1.1em;
}

@media (max-width: 550px) {
    .design-mobile {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		align-items: center;
	}
	.inner-portfolio-item-title{
		height:30px;
		position: static;
	}
}


.item-container {
	background-color: white;
	height: 250px;
	min-width: 200px;
	width: 200px;
	border-radius: 10px;
	position: relative;
	cursor: pointer;
	transition: box-shadow 100ms ease-out;
	border: 1px solid rgba(0, 0, 0, 0.4);
	box-shadow: 1px 1px 5px 2px rgba(136, 136, 136, 0.82);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	transition: 0.3s;
	position: relative;
	margin: 30px 0;
	transition: 0.5s;
}

.item-container:hover {
	box-shadow: 1px 1px 10px 5px rgba(119, 89, 117, 0.82);
	animation: myAnim2 0.5s ease 0s 1 normal forwards;
	transition: 0.3s;
}

.item-inner-container {
	height: 95%;
	width: 95%;
	border-radius: 10px;
	transition: 0.3s;
}

.item-hover {
	width: 100%;
	height: 100%;
	background-color: rgba(119, 89, 117, 0.95);
	border-radius: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	animation: myAnim3 0.2s ease 0s 1 normal forwards;
}

.item-hover-p {
	text-align: center;
	width: 95%;
	font-size: 1.3em;
	color: black;
	font-weight: bold;
	transition: 0.3s;
	animation: myAnim3 0.8s ease 0s 1 normal forwards;
}

.item-title {
	position: absolute;
	bottom: -65px;
	min-width: 150px;
	text-align: center;
	font-weight: 500;
	font-size: 1.1em;
}

.transition-effect {
	opacity: 0;
	transition: opacity 0.3s ease-in-out;
}

.portfolio-modal {
	width: 100%;
	min-height: 100vh;
	height: fit-content;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 9999;
	background-color: rgba(207, 207, 207, 0.884);
	display: flex;
	justify-content: center;
	align-items: center;
	animation: myAnim3 0.2s linear 0s 1 normal both;
}
.portfolio-modal-container {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	max-width: 1000px;
	width: 57vw;
	min-width: 300px;
	height: fit-content;
	max-height: 90vh;
	z-index: 50;
	transition: 0.3s;
	background-color: white;
	border-radius: 10px;
	box-shadow: 1px 1px 10px 2px rgba(119, 89, 117, 0.82);
	padding: 30px;
	min-width: 300px;
	overflow-y: auto;
}

.portfolio-modal-close-container {
	display: flex;
	justify-content: flex-end;
	width: 100%;
}
.portfolio-modal-close-icon {
	cursor: pointer;
}

.portfolio-modal-inner-container {
	height: 100%;
	width: 100%;
	display: flex;
	padding-bottom: 25px;
}

.portfolio-modal-left {
	width: 30%;
	margin-left: 30px;
	margin-right: 15px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	margin-top: 100px;
}

.portfolio-modal-right {
	width: 70%;
	margin-right: 30px;
	margin-left: 30px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	padding: 0px 10px;
}

.portfolio-modal-image-container {
	background-color: white;
	height: 300px;
	min-width: 200px;
	width: 100%;
	border-radius: 10px;
	border: 1px solid rgba(0, 0, 0, 0.4);
	box-shadow: 1px 1px 5px 2px rgba(136, 136, 136, 0.82);
	transition: 0.3s;
	padding: 5px;
}
.portfolio-modal-image {
	height: 100%;
	width: 100%;
}

.portfolio-modal-image-art {
	max-width: 600px !important;
	min-height: 500px !important;
	height: 100%;
	width: 100%;
	pointer-events: none;

}

.portfolio-modal-heading {
	display: flex;
	justify-content: center;
	width: 100%;
	margin-bottom: 15px;
}

.portfolio-modal-link-button {
	width: 100%;
	height: 50px;
	border-radius: 5px;
	border: 1px solid transparent;
	background-color: rgb(119, 89, 117);
	cursor: pointer;
	font-weight: 500;
	transition: 0.3s;
	color: white;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 40px 0px;
}

.portfolio-modal-link-button.link2 {
	background-color: rgb(11, 12, 107);
	margin-top: 0px;
}

.portfolio-modal-link-button:hover {
	font-weight: 900;
	border: 1px solid rgb(119, 89, 117);
	color: rgb(119, 89, 117);
	background-color: transparent;
	box-shadow: 1px 1px 5px 1px rgba(119, 89, 117, 0.82);
}

.portfolio-modal-tech {
	width: 100%;
	display: flex;
	font-size: 1.2em;
	align-items: center;
	flex-wrap: wrap;
}

.portfolio-modal-description {
	height: 60%;
	width: 100%;
	text-align: justify;
}

.portfolio-modal-description p {
	margin-left: 10px;
}

.portfolio-modal-description div {
	margin-top: 20px;
}

.icon-item {
	margin-left: 5px;
	display: flex;
	align-items: center;
}

.icon-modal {
	width: 20px;
	height: 20px;
	margin-right: 5px;
	height: 100%;
}

@keyframes myAnim {
	0% {
		opacity: 0;
		transform: translateY(-50px);
	}

	100% {
		opacity: 1;
		transform: translateY(0);
	}
}

@keyframes myAnim2 {
	0% {
		transform: scale(1);
	}

	100% {
		transform: scale(1.01);
	}
}
@media (max-width: 1400px) {
	.portfolio-modal-tech {
		font-size: 1em;
	}
}

@media (max-width: 1024px) {
	.inner-portfolio {
		max-width: 1000px;
		grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
		grid-gap: 10px 20px;
	}
	.portfolio-modal-inner-container {
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	.portfolio-modal-left {
		width: 80%;
		margin: 0px;
	}
	.portfolio-modal-right {
		width: 80%;
		margin: 0px;
	}
	.icon-item {
		margin: 3px;
	}
	.icon-modal {
		margin-right: 3px;
	}
}

@media (max-width: 800px) {
	.inner-portfolio {
		max-width: 750px;
		grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
		grid-gap: 10px 20px;
		justify-items: stretch;
		margin-left: 10px;
	}
	.item-container {
		min-width: 100px;
		width: 200px;
		height: 200px;
	}
}
@media (max-width: 767px) {
	.item-hover-p {
		font-size: 1em;
	}
	.icon-item {
		margin: 5px 0px;
	}
	.icon-name {
		margin-left: 5px;
	}
	.icon-modal {
		margin-right: 0px;
	}
}
@media (max-width: 670px) {
	.inner-portfolio {
		max-width: 550px;
		grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
		grid-gap: 10px 15px;
	}
	.item-container {
		min-width: 100px;
		width: 150px;
		height: 200px;
	}
	.inner-portfolio-less {
		width: 500px;
	}
}

@media (max-width: 550px) {
	.portfolio-container {
		min-height: fit-content;
		padding-bottom: 40px;
	}
	.portfolio-modal-container {
		width: 100%;
		padding-top: 30px;
		border-radius: 0;
		max-height: 100vh;
	}

	.inner-hover {
		min-height: 350px;
	}
	.inner-portfolio {
		max-width: 450px;
		grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
		grid-gap: 10px 20px;
	}
	.item-container {
		min-width: 100px;
		width: 110px;
		height: 150px;
	}
	.inner-portfolio-less {
		width: 370px;
	}
	.portfolio-modal-left {
		width: 65%;
	}
	.portfolio-modal-right {
		width: 90%;
	}
	.inner-portfolio-item-title {
		font-size: 0.9em;
		width: 100px;
		text-align: center;
	}
	.portfolio-modal-tech {
		font-size: 0.9em;
		text-align: left;
		align-items: center;
		flex-direction: column;
	}
	.portfolio-modal-description {
		font-size: 0.9em;
		margin: 0px 0px 20px 0px;
	}
	.portfolio-modal-description p {
		margin: 0px;
		margin-top: 20px;
		margin-bottom: 40px;
	}
	.portfolio-modal-link-button {
		margin: 20px 0px;
	}
	.portfolio-modal-heading {
		margin: 0px;
		font-size: 0.9em;
	}
}

@media (max-width: 480px) {
	.item-container {
		min-width: 100px;
		width: 100px;
		height: 160px;
	}
	.item-hover-p {
		font-size: 0.9em;
	}
	.inner-portfolio-less {
		width: 300px;
	}
}
