.header {
	width: 100%;
	display: flex;
	justify-content: space-around;
	align-items: center;
}
.empty-header {
	height: 100px;
}
.header > * {
	color: black;
	transition: 0.3s ease;
}
.header.sticky {
	position: fixed;
	top: 0;
	width: 100vw;
	background-color: rgb(151 118 149);
	z-index: 100;
	animation: header 0.3s linear 0s 1 normal forwards;
	border: 1px solid rgba(0, 0, 0, 0.4);
	box-shadow: 1px 1px 2px 2px rgba(136, 136, 136, 0.82);
}

.header.sticky * {
	color: black;
}
.heading {
	font-size: 2.4em;
	font-weight: bold;
	margin: 15px 0 0;
	transition: 0.3s;
}
.heading-smaller {
	font-size: 1.6em;
	font-weight: bold;
	transition: 0.3s;
	width: 100%;
	height: 100px;
	/* background-color: rgba(119, 89, 117, 0.95); */
	display: flex;
	align-items: center;
	justify-content: center;
}

.heading-middle {
	font-size: 1.2em;
	margin: -5px 0px 7px 5px;
	font-style: italic;
	transition: 0.3s;
}
.heading-right-container {
	display: flex;
	transition: 0.3s;
}
.heading-right-menu {
	position: absolute;
	right: 20px;
	top: 60px;
	animation: myAnim 1s ease 0s 1 normal forwards;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.heading-right-menu-sticky-mobile {
	background-color: rgb(151 118 149);
	width: 25%;
	right: 0px;
	top: 101px;
}

.links {
	font-size: 1.4em;
	margin: 0 20px;
	position: relative;
	font-weight: 500;
	transition: 0.3s;
	text-decoration: none !important;
}

.links:hover {
	cursor: pointer;
}
.links::before {
	content: "";
	position: absolute;
	width: 100%;
	height: 4px;
	border-radius: 4px;
	background-color: #18272f;
	bottom: 0;
	left: 0;
	transform-origin: right;
	transform: scaleX(0);
	transition: transform 0.3s ease-in-out;
	bottom: -5px;
}
.links:hover::before {
	transform-origin: left;
	transform: scaleX(1);
}

.footer {
	width: 100%;
	min-width: 300px;
	background-color: rgb(151 118 149);
	min-height: 110px;
	height: fit-content;
	display: flex;
	justify-content: center;
	justify-content: space-around;
	align-items: center;
	transition: 0.3s;
	border: 1px solid rgba(0, 0, 0, 0.4);
	box-shadow: 1px -1px 2px 2px rgba(136, 136, 136, 0.82);
	margin-top: 100px;
	font-size: 0.8em;
}

.inner-footer {
	width: 60%;
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	align-items: center;
	position: relative;
	transition: 0.3s;
	font-size: 1.1em;
}

.footer-links {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	width: fit-content;
}

.links.footer1 {
	margin: 0px;
	margin-right: 30px;
}
.footer-icon {
	margin: 0px 15px;
	width: 32px;
	display: flex;
	z-index: 20;
}

.footer-svg {
	width: 100%;
	z-index: 20;
}
.copied {
	background-color: rgba(228, 222, 227);
	color: black;
	height: 30px;
	width: 10%;
	position: absolute;
	top: -36px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 5px;
	font-size: 0.8em;
	animation: myAnim 0.5s ease 0s 1 normal forwards;
	box-shadow: 1px 1px 10px 2px rgba(119, 89, 117, 0.82);
}

@media (max-width: 1200px) {
	.heading {
		margin-left: 10px;
	}
	.heading-middle {
		margin-left: 15px;
	}
	.heading-right-container {
		margin-right: 15px;
	}
	.inner-footer {
		width: 65%;
		font-size: 0.8em;
	}
	.footer-icon {
		margin: 0px 10px;
	}
}

@media (max-width: 1024px) {
	.header > * {
		font-size: 1em;
	}
	.links {
		font-size: 1em;
		margin: 5px 10px;
	}
	.footer {
		height: 270px;
	}
	.inner-footer {
		flex-direction: column;
		height: 80%;
		justify-content: space-around;
	}
	.footer-icon {
		width: 25px;
	}
	.footer-divider {
		display: none;
	}
}

@media (max-width: 767px) {
	.header.sticky {
		height: 100px;
	}
}
@media (max-width: 480px) {
	.heading {
		font-size: 1.7em;
		margin-left: 5px;
		right: 0;
	}
	.heading-right-container {
		margin-right: 15px;
		font-size: 1.3em;
	}
	.links {
		font-size: 1.22em;
		margin: 5px 5px;
	}
	.links-menu {
		font-size: 0.8em;
		margin: 5px 5px;
		height:5vh;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.heading-middle {
		font-size: 1em;
		margin-left: 10px;
	}
	.footer {
		height: 250px;
	}
	.inner-footer {
		flex-direction: column;
	}
}
@keyframes header {
	0% {
		opacity: 0;
		transform: translateY(-50px);
	}

	100% {
		opacity: 1;
		transform: translateY(0);
	}
}
