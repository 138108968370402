html {
	scroll-behavior: smooth 3s;
}
body {
	margin: 0;
	font-family: "Mukta", sans-serif;
	overflow: auto;
	overflow-x: hidden;
	height: fit-content;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
	scrollbar-width: auto;
	scrollbar-color: #977695;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
	width: 16px;
}

*::-webkit-scrollbar-track {
	background: linear-gradient(to bottom, #977695 10%, #ffffff 30%, #ffffff 70%, #977695 90%) !important;
	border-left: 0.5px solid #000000;
}

*::-webkit-scrollbar-thumb {
	background-color: #7c607a;
	border-radius: 5px;
	border: 0.5px solid #000000;
}

.modal-open {
	overflow: hidden;
}

.content-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.overlay {
	width: 100%;
	height: 100%;
	background-image: linear-gradient(
		to top,
		#fff 0%,
		rgba(156, 141, 155, 0.35) 69%,
		rgba(119, 89, 117, 0.5) 100%
	);
	display: flex;
	justify-content: flex-start;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	min-width: 300px;
}

.content {
	max-width: 1000px;
	min-width: 300px;
	z-index: 10;
}

.scene {
	margin-top: 120px;
	object-fit: cover;
	height: 100%;
	max-width: 1050px;
	width: 60vw;
	min-width: 350px;
	z-index: 10;
	transition: 0.3s;
}

.svg {
	width: 100%;
	min-width: 280px;
	object-fit: cover;
}

.floor {
	position: absolute;
	top: 615px;
	width: 100vw;
	height: 500px;
	background-image: linear-gradient(
		to top,
		#fff 0%,
		rgba(245, 210, 168, 0.35) 70%,
		rgba(214, 156, 110, 0.5) 100%
	);
	z-index: 1;
	min-width: 300px;
	transition: 0.3s;
}

.overlay-content {
	background-image: linear-gradient(
		to top,
		#fff 5%,
		rgba(245, 210, 168, 0.5) 6000%
	);
	margin-top: 250px;
	height: 100%;
	width: 100%;
	transition: 0.3s;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

@media (max-width: 1500px) {
	.floor {
		margin-top: -80px;
	}
}

@media (max-width: 1200px) {
	.floor {
		margin-top: -150px;
	}
}

@media (max-width: 900px) {
	.floor {
		margin-top: -200px;
	}
}

@media (max-width: 800px) {
	.floor {
		margin-top: -220px;
	}
}

@media (max-width: 650px) {
	.floor {
		margin-top: -240px;
	}
}

@media (max-width: 550px) {
	.floor {
		margin-top: -275px;
	}
	.scene {
		margin-top: 100px;
	}
}
@media (max-width: 400px) {
	.floor {
		margin-top: -300px;
	}
}

@media (max-width: 350px) {
	.floor {
		margin-top: -315px;
	}
}
