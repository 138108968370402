.about-me-cards {
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	width: 75%;
	min-width: 200px;
	min-height: 610px;
	animation: myAnim3 0.5s ease 0s 1 normal forwards;
	font-size: 1em;
	transition: 0.3s;
}

.about-me-info-content {
	margin-top: 15px;
	font-size: 1.05em;
}
.about-me-image-container {
	width: 100%;
	display: flex;
	justify-content: center;
	height: 150px;
	margin-top: 50px;
}
.about-me-image {
	width: 150px;
}

.resume-link {
	text-decoration: none !important;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-top: 25px;
}
.resume {
	color: black;
	margin-bottom: 25px;
	font-size: 1.1em;
}

.about-me-certiticates-content {
	width: 100%;
	margin-top: 5px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
}

.about-me-certiticates-heading {
	color: rgb(90, 65, 88);
	font-size: 1.2em;
	text-align: center;
	margin-bottom: 20px;
}

.about-me-certiticates-display {
	display: flex;
	justify-content: space-between;
	margin-top: 2px;
	margin-bottom: 2px;
}

.about-me-certiticates-display-column {
	display: flex;
	flex-direction: column;
}

.about-me-certiticates-name {
	margin-left: 5px;
	font-size: 1em;
}
.about-me-certiticates-institution {
	margin-left: 8px;
	font-style: italic;
	font-size: 0.85em;
}
.about-me-skills {
	width: 100%;
	min-height: 500px;
	display: flex;
	flex-direction: column;
	height: fit-content;
	justify-content: flex-start;
	padding-bottom: 10px;
}

.about-me-skills-tehnical,
.about-me-skills-soft {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
}
.about-me-skills-heading {
	color: rgb(90, 65, 88);
	font-size: 1.2em;
	text-align: center;
	width: 100%;
	margin-bottom: 20px;
}

.about-me-skills-center {
	display: flex;
	width: 100%;
	justify-content: center;
	align-items: center;
}

.about-me-skills-wrap {
	width: 85%;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;
	margin: 10px 0;
}

.about-me-skills-item {
	display: flex;
	margin-top: 3px;
	margin-bottom: 3px;
	margin-left: 5px;
	width: calc(33.33% - 15px);
	box-sizing: border-box;
	height: 40px;
	padding-left: 25px;
}

.icon-class {
	width: 20px;
	height: 20px;
	margin-right: 10px;
}

@media (max-width: 1650px) {
	.about-me-skills-wrap {
		width: 100%;
	}
	.about-me-cards {
		font-size: 0.95em;
	}
}

@media (max-width: 1200px) {
	.about-me-cards {
		font-size: 0.95em;
	}
	.about-me-skills-item {
		height: 60px;
	}
}

@media (max-width: 1000px) {
	.about-me-cards {
		font-size: 0.9em;
	}
	.about-me-skills-item {
		width: calc(33.33% - 20px);
		height: 50px;
		padding-left: 5px;
	}
}

@media (max-width: 700px) {
	.about-me-cards {
		font-size: 0.87em;
	}
	.about-me-skills-item {
		width: calc(33.33% - 5px);
	}
	.about-me-skills-wrap {
		width: 85%;
	}
}

@media (max-width: 550px) {
	.about-me-skills {
		padding-bottom: 40px;
	}
	.about-me-cards {
		font-size: 0.8em;
		width: 85%;
	}
	.resume-container {
		font-size: 0.9em;
	}
	.about-me-certiticates-display {
		margin-top: 5px;
		margin-bottom: 5px;
	}
	.about-me-certiticates-name {
		max-width: 250px;
	}
	.about-me-skills-item {
		width: 33.33%;
		height: 50px;
	}
	.about-me-skills-wrap {
		justify-content: space-between;
		text-align: start;
	}
}

@media (max-width: 400px) {
	.about-me-cards {
		width: 83%;
	}
	.about-me-p {
		font-size: 0.85em;
	}
}
