.contact-container {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	max-width: 1050px;
	width: 60vw;
	min-width: 300px;
	min-height: 600px;
	height: fit-content;
	z-index: 50;
	transition: 0.3s;
	margin: 10px 0px;
	/* background-color: rgba(119, 89, 117, 0.199); */
	border-radius: 10px;
	box-shadow: 1px 1px 10px 2px rgba(119, 89, 117, 0.82);
	animation: myAnim3 0.3s linear 0s 1 normal both;
}

.contact-headings {
	display: flex;
	justify-content: center;
	width: 100%;
	background-color: rgba(119, 89, 117, 0.199);
	border-top-right-radius: 10px;
	border-top-left-radius: 10px;
}
.contact-heading {
	font-size: 1.6em;
	color: rgb(92, 68, 90);
	text-shadow: 2px 2px 4px rgba(179, 147, 211, 0.1),
		3px 4px 4px rgba(179, 147, 211, 0.15), 4px 6px 4px rgba(179, 147, 211, 0.2),
		5px 8px 4px rgba(179, 147, 211, 0.25);
}
.contact-form-container {
	display: flex;
	width: 90%;
	padding: 20px 0px 0px 0px;
}
.contact-form {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	font-family: "Mukta", sans-serif;
}

.contact-input {
	border-color: rgb(119, 89, 117);
	border-radius: 5px;
	border-width: 1px;
	height: 30px;
	margin: 20px 0px;
	width: 65%;
	padding:0px 4px;
	/* background-color: rgb(75 75 75 / 6%); */
	box-shadow: inset 0px 0px 10px -1px rgb(119, 89, 117, 0.4);
}

.contact-textarea {
	min-width: 65%;
	min-height: 250px;
	max-width: 600px;
	max-height: 250px;
	border-color: rgb(119, 89, 117);
	border-radius: 5px;
	border-width: 1px;
	margin: 20px 0px;
	padding:4pxpx 4px;
	/* background-color: rgb(75 75 75 / 6%); */
	box-shadow: inset 0px 0px 10px -1px rgb(119, 89, 117, 0.4);
}

.contact-button {
	width: 160px;
	height: 40px;
	border-radius: 5px;
	border:1px solid transparent;
	background-color: rgb(119, 89, 117);
	border-color: rgb(119, 89, 117);
	cursor: pointer;
	font-weight: 500;
	transition: 0.3s;
	margin: 10px 0px 30px 0px;
	color: white;
}

.contact-button:hover {
	font-weight:700;
	border:1px solid rgb(119, 89, 117);
	color:  rgb(119, 89, 117);
	background-color: transparent;
	box-shadow: 1px 1px 5px 1px rgba(119, 89, 117, 0.82);
}
@media (max-width: 950px) {
	.contact-heading {
		font-size: 1.2em;
		text-align: center;
	}
}
@media (max-width: 550px) {
	.contact-container {
		width: 90vw;
	}

	.contact-heading {
		font-size: 1em;
		text-align: center;
	}
	.contact-textarea {
		max-width: 200px;
	}
	.contact-button {
		width: 130px;
		height: 40px;
		font-size: 0.7em;
	}
	.contact-textarea {
		margin: 17px 0px;
		margin-bottom: 25px;
		min-width: 70%;
	}
	.contact-input {
		margin: 17px 0px;
		width: 70%;
	}

	.contact-form-container {
		padding: 15px 0px 15px 0px;
	}
}
