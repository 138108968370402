.about-me-container {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;
	max-width: 1050px;
	width: 60vw;
	min-width: 300px;
	height: fit-content;
	z-index: 50;
	transition: 0.3s;
	margin: 10px 0px;
	/* background-color: rgba(119, 89, 117, 0.199); */
	border-radius: 10px;
	box-shadow: 1px 1px 10px 2px rgba(119, 89, 117, 0.82);
	animation: myAnim3 0.3s linear 0s 1 normal both;
}

.about-me-arrows-container {
	display: flex;
	height: 100%;
	align-items: center;
	justify-content: center;
	width: 8%;
	color: rgba(119, 89, 117, 0.82);
	transition: 0.3s;

}
.about-me-arrows-container {
	color: rgba(119, 89, 117, 0.39);
}
.about-me-arrows:hover {
	transform: scale(5);
	cursor: pointer;
	color: rgb(119, 89, 117);
	transition: 0.3s;
}

.about-me-headings {
	display: flex;
	justify-content: space-between;
	width: 100%;
	background-color: rgba(119, 89, 117, 0.199);
	border-top-right-radius: 10px;
	border-top-left-radius: 10px;
}

.faded-headings:hover {
	transform: scale(1.1);
	cursor: pointer;
	color: rgb(104, 79, 102);
	transition: 0.3s;
}


.main-heading {
	font-size: 1.6em;
	color: rgb(92, 68, 90);
	text-shadow: 2px 2px 4px rgba(179, 147, 211, 0.1),
		3px 4px 4px rgba(179, 147, 211, 0.15), 4px 6px 4px rgba(179, 147, 211, 0.2),
		5px 8px 4px rgba(179, 147, 211, 0.25);
}
.faded-headings {
	font-size: 1.2em;
	font-weight: 600;
	color: rgb(92, 68, 90);
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	cursor: pointer;
	opacity: 0.3;
	width: 150px;
}

.fade-enter-left {
	animation: myAnimLeft 0.5s ease;
}

.fade-enter-right {
	animation: myAnimRight 0.5s ease;
}

.about-me-content {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	min-width: 100%;
	height: 100%;
	flex: 1;
}

@keyframes myAnim3 {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

@keyframes myAnimRight {
	0% {
		opacity: 0;
		transform: translateX(50px);
	}

	100% {
		opacity: 1;
		transform: translateX(0);
	}
}

@keyframes myAnimLeft {
	0% {
		opacity: 0;
		transform: translateX(-50px);
	}

	100% {
		opacity: 1;
		transform: translateX(0);
	}
}
@media (max-width: 950px) {
	.about-me-container {
		margin-top: 100px;
	}
	.main-heading {
		font-size: 1.2em;
		text-align: center;
	}
	.faded-headings {
		font-size: 0.9em;
	}
}
@media (max-width: 550px) {
	.about-me-container {
		width: 90vw;
	}
	.about-me-arrows-container {
		width: 0%;
	}

	.main-heading {
		font-size: 1em;
		text-align: center;
	}
	.faded-headings {
		font-size: 0.8em;
		width: 80px;
	}
}

@media (max-width: 400px) {
}
